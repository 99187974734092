import React, { useState } from 'react';
import { Link } from 'react-router-dom';
//components
import Logosection from '../components/Logosection';
import Container from 'react-bootstrap/Container';
//constant
import Url from '../constants/Url';
import { useSelector } from 'react-redux';
import Moment from 'react-moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const Home = props => {

  const typologies = useSelector(state => state.typologies.all);
  const filteredTypologies = useSelector(state => state.typologies.filteredTypologies);
  const galerie = useSelector(state => state.galerie.galerie);
  const expositions = useSelector(state => state.expos.expos);

  return(

    <div>
    {
        props.page === "Home" ?
        <div id="fh5co-news">
          <Container>
          <Link to="/">
          <picture className="row top-line animate-box" id="picture-logo">
          <img src={`${Url.garner_Cdm}logos/logo-garner.jpeg`} alt="garner" />
          </picture>
          </Link>
          <Logosection data={typologies}/>
          </Container>
        </div>
      :
      props.page === "Selection" && filteredTypologies !== undefined ?
      <div id="fh5co-news">
      <Container>
      <Link to="/">
      <picture className="row top-line animate-box" id="picture-logo">
      <img src={`${Url.garner_Cdm}logos/logo-garner.jpeg`} alt="garner" />
      </picture>
      </Link>
      <Logosection data={filteredTypologies}/>
      </Container>
    </div>
    :
    props.page === "Biographie" && galerie !== undefined ?
      <div id="fh5co-news">
      <Container >
      <Link to="/">
      <picture className="row top-line animate-box" id="picture-logo">
      <img src={`${Url.garner_Cdm}logos/logo-garner.jpeg`} alt="garner" />
      </picture>
      </Link>
      <Container className='container-bio'>
          <h1>{galerie[0].titre}</h1>

          <p className='biographie-p'><img src={`${Url.garner_Cdm}biographie/pascal_garnier.jpg`} className='image'/> 
          {galerie[0].description1}
          <p className='biographie-p'>{galerie[0].description2}</p>
          <img src={`${Url.garner_Cdm}biographie/Bandeau-Bio.jpg`} className='image-bandeau'/> 
          <p className='biographie-p'>{galerie[0].description3}</p>
          </p>
      </Container>

      </Container>
    </div>
    :
    props.page === "Portrait-Fiction" && galerie !== undefined ?
      <div id="fh5co-news">
      <Container >
      <Link to="/">
      <picture className="row top-line animate-box" id="picture-logo">
      <img src={`${Url.garner_Cdm}logos/logo-garner.jpeg`} alt="garner" />
      </picture>
      </Link>
      <Container className='container-bio'>
          <h1>{galerie[1].titre}</h1>

          <p className='biographie-p'><img src={`${Url.garner_Cdm}biographie/pascal_garnier.jpg`} className='image'/> 
          {galerie[1].description1}
          <p className='biographie-p'>{galerie[1].description2}</p>
          <img src={`${Url.garner_Cdm}biographie/Actualites-bis.jpg`} className='image-bandeau'/> 
          <p className='biographie-p'>{galerie[1].description3}</p>
          </p>
      </Container>

      </Container>
    </div>
    :
    props.page === "Agenda" && expositions !== null?
    <div id="fh5co-author">
      <Container>
      <Link to="/">
      <picture className="row top-line animate-box" id="picture-logo">
      <img src={`${Url.garner_Cdm}logos/logo-garner.jpeg`} alt="garner" />
      </picture>
      </Link>
      <Container className='container-bio'>
        <Row>

        <Col style={{textAlign:'center'}}>
        {expositions.map((item)=>{
          return(
            <p key={item.id} className="animate-box work-grid item">

                  {item.boolDisplayDate?
                    <div>
                      <hr />
                    <div className="paddingLeft">
                      <p>
                      <h3 >
                      <Moment format="YYYY" className="orange">
                      {item.annee}
                      </Moment>
                      </h3>
                      </p>
                      <h3>
                      <span className="bold">{item.titre}</span><br/>
                      <span>{item.adresseVernissage.charAt(0).toUpperCase()+item.adresseVernissage.slice(1)}</span>
                      </h3>
                    </div>
                    </div>
                    :
                    <div className="paddingLeft">
                      <h3>
                      <span className="bold">{item.titre}</span><br/>
                      <span>{item.adresseVernissage.charAt(0).toUpperCase()+item.adresseVernissage.slice(1)}</span>
                      </h3>
                    </div>
                  }
            </p>)
          })}
          </Col>
          </Row>
          </Container>
          </Container>
      </div>
      :
    <div></div>
    }
    </div>
  )



}

export default Home;
