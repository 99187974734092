import React from 'react';
import Grid from '../components/Grid';
import '../styles/oeuvres.css';


const Logosection = props => {

  window.scrollTo(0, 0);

  return(
    <div>
    {
           props.data.map((item, index)=>{
           return (<Grid key={index} item={item} page={props.page} />)
           })
    }
    </div>
  )
}

export default Logosection;
