import React, { useState } from 'react';
import { Link } from 'react-router-dom';
//components
import Logosection from '../components/Logosection';
import Container from 'react-bootstrap/Container';
//constant
import Url from '../constants/Url';
import { useDispatch, useSelector } from 'react-redux';
import { artworkSelected } from '../features/typologies.slice';
import 'bootstrap/dist/css/bootstrap.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';



const Portfolio = props => {

  const dispatch = useDispatch();
  let items = [];

  const themeSelected = useSelector(state => state.typologies.typologySelected.theme);
  const typologySelected = useSelector(state => state.typologies.typologySelected);
  const artworks = useSelector(state => state.typologies.artworkOfSelectedTypology);

  const loadArtWorks = () =>{
    console.log("loadArtWorks******************************************************************");
    console.log("number of artWorks : "+artworks.length);
        for (const id in artworks){

          items.push(
            <Row key={artworks[id].id}>
              <Col md={{offset:2,span:8,offset:2}}>
            <p  className="animate-box center">
              <img
                src={`${Url.garner_Cdm}themes/${themeSelected}/${artworks[id].nomFichier.replaceAll(" ","%20")}`}
                alt={artworks[id].technique} className="img-responsive center" style={{maxHeight:"85vh"}}/>
            </p>
            <div className="testimony top-center center">
              <blockquote className="animate-box">
    
                <p className="center">{artworks[id].titreOeuvre}</p>
                <h4 className="person center">
                  <cite className="center">{artworks[id].technique}</cite>
                </h4>
                <Link to="/Contact" className="btn btn-primary center" 
                  onClick={()=>getArtWork(artworks[id])}>
                  En savoir plus
                </Link>
              </blockquote>
            </div>
            </Col>
          </Row>
          );
        }
        return items;
    }

    const getArtWork = (a) => {
      console.log("getArtWork");
      //console.log(a);
      let artWork = a;
      console.log(artWork);
      dispatch(artworkSelected(artWork));
    }

  return(

    <div>
        <div id="fh5co-news">
          <Container>
          <Link to="/">
          <picture className="row top-line animate-box" id="picture-logo">
          <img src={`${Url.garner_Cdm}logos/logo-garner.jpeg`} alt="garner" />
          </picture>
          </Link>
          <div id="fh5co-author">
              <Container>
                <Row className="top-line">
                  <Col md={{offset:1,span:10,offset:1}}
                    className="fh5co-heading">
                    <h2 className="center">{typologySelected.libelle}</h2>
                  </Col>
                </Row>
                  {
                    loadArtWorks()
                  }
              </Container>
              </div>
          </Container>
        </div>
    </div>
  )



}

export default Portfolio;
