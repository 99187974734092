import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Url from '../constants/Url';
import 'bootstrap/dist/css/bootstrap.css';
import Col from 'react-bootstrap/Col';
import { typologySelected, artworkOfSelectedTypology } from '../features/typologies.slice';

const Grid = props => {

  const dispatch = useDispatch();

  const updateSelectedTypologyId = async() => {
    console.log("updateSelectedTypologyId : "+ props.item.idTypologie);
    dispatch(typologySelected(props.item));
    const response = await axios.get(`${Url.garner_Api}artWork/allByTypologyId/${props.item.idTypologie}`);
    console.log("response artworkOfSelectedTypology :"+response.data.results);
    dispatch(artworkOfSelectedTypology(response.data.results));
  }



return(
  <div>
  {

    <Col sm={12} md={12} lg={4} className="text-center animate-box">
      <Link to="/Portfolio"  className="news" href={props.item.id}>
        <div onClick={updateSelectedTypologyId} className="news-grid" style={{backgroundImage:`url(${props.item.nomFichier})`, textAlign: 'center'}}>
          <div className="inner">
            <div className="desc">
              <h3>{props.item.libelle}</h3>
            </div>
          </div>
        </div>
      </Link>
    </Col>

  }
  </div>
)
}

export default Grid;
