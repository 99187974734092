import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios';

import Url from '../constants/Url';

import '../styles/contactForm.css';

const ContactForm = () => {
  const captchaRef = useRef(null);
  const artworkSelected = useSelector(state => state.typologies.artworkSelected);

  const [from, setFrom] = useState("contact@leadev.fr");
  //const [to, setTo] = useState("pascal.garnier74@orange.fr");
  const [to, setTo] = useState("contact@leadev.fr");
  const [email, setEmail] = useState("");
  const [nom, setNom] = useState("");
  const [phone, setPhone] = useState("");

  const [message, setMessage] = useState("Veuillez renseigner l'ensemble des champs du formulaire.");
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");
  const [status, setStatus] = useState("INIT");

  const [subject, setSubject] = useState(artworkSelected!==null?`GARNER - Réalisation : ${artworkSelected.titreOeuvre}`:"Website");
  const [text, setText] = useState(artworkSelected!==null?`Bonjour, je souhaiterai en savoir plus sur votre travail : ${artworkSelected.titreOeuvre}, pourriez-vous me recontacter s'il vous plaît  ?`:"Bonjour, je suis intéressé(e) par vos réalisations ...");

  const sendMailTo = async () => {
    console.log(from + " " + to + " " + subject + " " + text);

    let msg = {
        personalizations: [
          {
            to: [{ email: to }],
            dynamic_template_data: {
              // Fill in the dynamic template data as per your template's requirements
              // For example:
              message: `${text}`, // Sanitize message
              fullname: `${nom} - ${phone}`, // Sanitize fullName
              email: `${from}`, // Sanitize email
            },
          },
        ],
        from: { email: 'contact@leadev.fr' },
        template_id: 'd-d6186158c4e34cd1a2473feb0a5c8457', // Replace with your template ID
      };

      

    try {
      await axios.post(`${Url.garner_Api}contact`, msg);
      
      setStatus("OK");
      setSuccessMessage("Votre message a bien été envoyé");



    } catch (err) {
        console.log(err.message);
      setStatus("KO");
      setError("Une erreur est intervenue lors de l'envoi de votre message. Veuillez vérifier le formulaire.");
    }

    await sendMeACopy();
    // Reset message after 5 seconds
    setTimeout(() => {
      setMessage("Veuillez renseigner l'ensemble des champs du formulaire.");
      setSuccessMessage("");
      setError("");
    }, 5000);
  };

  const sendMeACopy =async()=>{

    console.log(from + " " + to + " " + subject + " " + text);

    let msg = {
        personalizations: [
          {
            to: [{ email: "lea.erika.picard@gmail.com" }],
            dynamic_template_data: {
              // Fill in the dynamic template data as per your template's requirements
              // For example:
              message: `${text}`, // Sanitize message
              fullname: `${nom} - ${phone}`, // Sanitize fullName
              email: `${from}`, // Sanitize email
            },
          },
        ],
        from: { email: 'contact@leadev.fr' },
        template_id: 'd-d6186158c4e34cd1a2473feb0a5c8457', // Replace with your template ID
      };

    try {
        await axios.post(`${Url.garner_Api}contact`, msg);
             // Clear all fields
      setNom("");
      setEmail("");
      setSubject("");
      setPhone("");
      setText("");
  
      } catch (err) {
        console.log(err.message);
      }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container lg={{ offset: 3, span: 6, offset: 3 }}>
      <Form>
        <Row className="mb-3">

          <Col>
            {status === "INIT" && successMessage!==""? (
              <div className="alert alert-success" style={{fontFamily:"helveticaMediumExtended",fontSize:16}}>{message}</div>
            ) : status === "OK" && successMessage!==""? (
              <div className="alert alert-success" style={{fontFamily:"helveticaMediumExtended",fontSize:16}}>{successMessage}</div>
            ) : status === "KO" && error!=="" ? (
              <div className="alert alert-danger" style={{fontFamily:"helveticaMediumExtended",fontSize:16}}>{error}</div>
            ) : (<></>)
            }
          </Col>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridNom">
            <Form.Label className="myformlabel">NOM Prénom</Form.Label>
            <Form.Control type="input" placeholder="Votre identité" className="myforminput" value={nom} onChange={(e) => setNom(e.target.value)} />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label className="myformlabel">Email</Form.Label>
            <Form.Control type="email" placeholder="Email" className="myforminput" value={email} onChange={(e) => setEmail(e.target.value)} />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridSubject">
            <Form.Label className="myformlabel">Sujet</Form.Label>
            <Form.Control type="input" placeholder="Sujet" className="myforminput" value={subject} onChange={(e) => setSubject(e.target.value)} />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPhone">
            <Form.Label className="myformlabel">Téléphone</Form.Label>
            <Form.Control type="input" placeholder="Telephone" className="myforminput" value={phone} onChange={(e) => setPhone(e.target.value)} />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridMessage">
            <Form.Label className="myformlabel">Message</Form.Label>
            <Form.Control as="textarea" row={3} placeholder="Message" className="myforminput" value={text} onChange={(e) => setText(e.target.value)} />
          </Form.Group>
        </Row>
        <Row className="mb-3"></Row>
        <Row className="mb-3"></Row>

        <Button
          type="button"
          variant="primary"
          onClick={sendMailTo}
          className="btnFormSend"
          disabled={!nom || !email || !subject || !phone || !text}
        >
          <span className="btn-envoyer">Envoyer</span>
        </Button>
      </Form>
    </Container>
  );
};

export default ContactForm;
