import { createSlice } from "@reduxjs/toolkit";


export const themesSlice = createSlice({
    name:"themes",
    initialState:{
        all:[],
        title:"themes",
        displayText:false,
        themeSelected:{},
        typologiesOfSelectedTheme: []
    },
    reducers:{
        allThemes:(state, {payload})=>{
            console.log("*************allThemes : "+payload.length);
            state.all = payload;
        },
        themeSelected:(state, {payload})=>{
            state.themeSelected = payload;
        },
        typologiesOfSelectedTheme:(state, {payload})=>{
            state.typologiesOfSelectedTheme = payload;
        }
    },
});

export const { allThemes, themeSelected, typologiesOfSelectedTheme } = themesSlice.actions;
export default themesSlice.reducer;