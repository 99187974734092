import { createSlice } from "@reduxjs/toolkit";

export const galerieSlice = createSlice({
  name: "galerie",
  initialState:{
  galerie:null,
  title:"À propos",
  displayText:true
  },
  reducers:{
    setGalerie:(state, {payload})=>{
      state.galerie = payload;
    }
  },
});

export const { setGalerie } = galerieSlice.actions;
export default galerieSlice.reducer;
