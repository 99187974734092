import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "../styles/navbar.css";
import { Link } from 'react-router-dom';
import { themeSelected } from '../features/themes.slice';
import Url from '../constants/Url';
import axios from 'axios';
import { filteredTypologies } from '../features/typologies.slice';


const Navbar =()=> {


  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isDropDownThemeExpanded, setIsDropDownThemeExpanded] = useState(false);


  const dispatch = useDispatch();

  const changeTheme = async(id) =>{

    try {
      dispatch(themeSelected(id));
      let buffer = new Array();

      const response = await axios.get(`${Url.garner_Api}typologies/allByThemeId/${id}`);
      console.log(response.data.results);
  
      let typologies = new Array();
      typologies = response.data.results;
  
      for(var i=0 ; i < typologies.length ; i++){
        var typology = typologies[i];
        console.log("********************* "+typology.libelle);

        let nomFichier = "";
      await axios.get(`${Url.garner_Api}artWork/displayHomeByTypologyId/${typology.idTypologie}`)
      .then( res => nomFichier = res.data.results[0].nomFichier)
      .catch(err => console.log(err));

      console.log("nom fichier : "+nomFichier);

      var nomLowerCase = typology.theme;
      var nomFichierFormatted = nomFichier.replaceAll(" ","%20");
      var path=`${Url.garner_Cdm}themes/${nomLowerCase}/${nomFichierFormatted}`;
      typology.nomFichier = path;
      console.log("** : "+nomLowerCase +" ** nomFichier url ** : "+typology.nomFichier);




        buffer.push(typology);
      }
      dispatch(filteredTypologies(buffer));
    } catch (error) {
      console.log(error);
    }

  }



  return (

    <nav className="navigation fh5co-nav">

      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded)
        }}
      >
      {/* icon from heroicons.com */}

        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
</svg>
      </button>

      <div  className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}>
        <ul>

          :<div></div>

          <li>
            <div className="dropdown"><Link to={"/"} onClick={()=>setIsDropDownThemeExpanded(!isDropDownThemeExpanded)}>Themes</Link>
            {isDropDownThemeExpanded?
              <div className="dropdown-content">
               <Link className="custom" to={"/Selection"} onClick={()=>{setIsNavExpanded(!isNavExpanded); changeTheme(1)}}>Portrait Fiction</Link>
                <Link className="custom" to={"/Selection"} onClick={()=>{setIsNavExpanded(!isNavExpanded); changeTheme(14)}}>Musiciens</Link>
                <Link className="custom" to={"/Selection"} onClick={()=>{setIsNavExpanded(!isNavExpanded); changeTheme(15)}}>Tourisme</Link>
                <Link className="custom" to={"/Selection"} onClick={()=>{setIsNavExpanded(!isNavExpanded); changeTheme(16)}}>New-York</Link>
                <Link className="custom" to={"/Selection"} onClick={()=>{setIsNavExpanded(!isNavExpanded); changeTheme(17)}}>New-York Black & White</Link>
                <Link className="custom" to={"/Selection"} onClick={()=>{setIsNavExpanded(!isNavExpanded); changeTheme(18)}}>Architecture</Link>
                <Link className="custom" to={"/Selection"} onClick={()=>{setIsNavExpanded(!isNavExpanded); changeTheme(19)}}>Edition</Link>
                <Link className="custom" to={"/Selection"} onClick={()=>{setIsNavExpanded(!isNavExpanded); changeTheme(20)}}>Presse</Link>
                <Link className="custom" to={"/Selection"} onClick={()=>{setIsNavExpanded(!isNavExpanded); changeTheme(21)}}>Bande Dessinée</Link>
                <Link className="custom" to={"/Selection"} onClick={()=>{setIsNavExpanded(!isNavExpanded); changeTheme(23)}}>Traits</Link>
                <Link className="custom" to={"/Selection"} onClick={()=>{setIsNavExpanded(!isNavExpanded); changeTheme(25)}}>Collage</Link>
              </div>
              :
              <div></div>
            }
            </div>
          </li>


          <li className="menu-li">
            <Link to={"/Biographie"} onClick={()=>setIsNavExpanded(!isNavExpanded)}>Biographie</Link>
          </li>

          <li className="menu-li">
            <Link to={"/Portrait-Fiction"} onClick={()=>setIsNavExpanded(!isNavExpanded)}>Portrait-Fiction</Link>
          </li>


          <li className="menu-li">
            <Link to={"/Agenda"} onClick={()=>setIsNavExpanded(!isNavExpanded)}>Agenda</Link>
          </li>

          <li className="menu-li">
            <Link to={"/Contact"} onClick={()=>setIsNavExpanded(!isNavExpanded)}>Contact</Link>
          </li>



        </ul>
      </div>
    </nav>



  );
}

export default Navbar;

// <li className="menu-li">
//   <Link to={"/Boutique"} style={{color:"#FF8C00"}} onClick={()=>{setIsNavExpanded(!isNavExpanded), changeTheme(1)} >E-ArtShop</Link>
// </li>
