import { configureStore } from '@reduxjs/toolkit'
import themesReducer from '../features/themes.slice'
import typologiesReducer from '../features/typologies.slice'
import galerieReducer from '../features/galerie.slice'
import exposReducer from '../features/expositions.slice'

const reducer = {
  themes:themesReducer,
  typologies:typologiesReducer,
  galerie:galerieReducer,
  expos:exposReducer
}

const store = configureStore({
  reducer: reducer,
  devTools: true,
})

export default store;
