import React, { useState } from 'react';
import { Link } from 'react-router-dom';
//components
import Logosection from '../components/Logosection';
import Container from 'react-bootstrap/Container';
//constant
import Url from '../constants/Url';
import { useDispatch, useSelector } from 'react-redux';
import { artworkSelected } from '../features/typologies.slice';
import 'bootstrap/dist/css/bootstrap.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ContactForm from '../components/ContactForm';


const Contact = props => {



  return(

    <div>
        
        <div id="fh5co-contact">
            <Container className='container-bio'>
              <Row className="top-line animate-box">
                    <Col md={{span:6, offset:3, push:2}} className="text-left fh5co-heading">
                        <h1 style={{fontWeight: "bold", display: "none"}}>Pascal Garnier | Paris | Garner</h1>
                            <h2>Me contacter</h2>
                    </Col>
              </Row>
              <Row>
                    <ContactForm />
              </Row>
            </Container>
				  </div>

    </div>
  )



}

export default Contact;
