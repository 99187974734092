import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Url from '../constants/Url';

const Footer = () => {

return(
  <footer id="fh5co-footer" role="contentinfo" style={{backgroundColor:"white"}}>
                  <Container>
                    <Row className="copyright">
                      <Col md={12} className="text-center">
                        <p>
                            <img src={`${Url.garner_Cdm}logos/logo-garner.jpeg`} alt="garner" style={{width:"10%",minWidth:"100px",marginBottom:"2%"}}/>
                        </p>
                        <ul>
                          <li>
                           <a href="https://www.leadev.fr" target="_blank">© 2017-2024 LEADEV - All Rights Reserved</a>
                      </li>  
                      </ul>
                        
                      </Col>
                    </Row>
                  </Container>
                </footer>
)

}

export default Footer;
