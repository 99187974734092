import { createSlice } from "@reduxjs/toolkit";


export const typologiesSlice = createSlice({
    name:"typologies",
    initialState:{
        all:[],
        title:"typologies",
        displayText:false,
        typologySelected:{},
        artworkOfSelectedTypology:[],
        artworkSelected:{},
        filteredTypologies:[]
    },
    reducers:{
        allTypologies:(state, {payload})=>{
            console.log("allTypologies : "+payload.length);
            state.all = payload;
        },
        typologySelected:(state, {payload})=>{
            state.typologySelected = payload;
        },
        artworkOfSelectedTypology:(state, {payload})=>{
            state.artworkOfSelectedTypology = payload;
        },
        artworkSelected:(state, {payload})=>{
            state.artworkSelected = payload;
        },
        filteredTypologies:(state, {payload})=>{
            state.filteredTypologies = payload;
        }
    },
});

export const { allTypologies, typologySelected, artworkOfSelectedTypology, artworkSelected, filteredTypologies } = typologiesSlice.actions;
export default typologiesSlice.reducer;