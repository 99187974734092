import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
//constants
import Url from './constants/Url';
//screens
import Home from './screens/Home';
//components
import Header from './components/Header';
import Footer from './components/Footer';
//axios
import axios from 'axios';
//features
import { allTypologies } from "./features/typologies.slice";
import { allThemes } from "./features/themes.slice";
import Portfolio from "./screens/Portfolio";
import Contact from "./screens/Contact";
import { setGalerie } from "./features/galerie.slice";
import { allExpositions } from "./features/expositions.slice";

const App = () => {


  //ReduxToolkit
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
 //const themeTitle = useSelector(state => state.typologies.filteredTypologies[0].libelle);


  const loadThemes = async() =>{
    const response = await axios.get(`${Url.garner_Api}themes/all`);
    dispatch(allThemes(response.data.results));
  }

  const loadTypologies = async() =>{
    const response = await axios.get(`${Url.garner_Api}typologies/all`);
    let all = new Array();
    all = response.data.results;

    let allBuffer = [];
    for (var i=0; i< all.length ; i++){
      let typology = all[i];

      let nomFichier = "";
      await axios.get(`${Url.garner_Api}artWork/displayHomeByTypologyId/${typology.idTypologie}`)
      .then( res => nomFichier = res.data.results[0].nomFichier)
      .catch(err => console.log(err));

      console.log("nom fichier : "+nomFichier);

      var nomLowerCase = typology.theme;
      var nomFichierFormatted = nomFichier.replaceAll(" ","%20");
      var path=`${Url.garner_Cdm}themes/${nomLowerCase}/${nomFichierFormatted}`;
      typology.nomFichier = path;
      console.log("** : "+nomLowerCase +" ** nomFichier url ** : "+typology.nomFichier);

      allBuffer.push(typology);
      //console.log(allArtists.length);

    }


    dispatch(allTypologies(allBuffer));

  }

  const loadGalerie =async()=>{
    let galeries = new Array();

    const response = await axios.get(`${Url.garner_Api}galerie/all`);
    console.log(response.data.results);
    
    galeries = response.data.results;

    dispatch(setGalerie(galeries));
  }

const loadExpositions = async() =>{

  let expositions = new Array();
  const response = await axios.get(`${Url.garner_Api}exposition/all`);
  console.log("****************************");
  console.log("*********** "+response.data.results);

  expositions = response.data.results;

  dispatch(allExpositions(expositions));

}


if(isLoading){

  loadThemes();
  loadTypologies();
  loadGalerie();
  loadExpositions();
  
  setIsLoading(false);
}



  return (
    <div id="page">
      {
        isLoading?
        <></>
        :
        <Router>
        <Header/>
    
              <Switch>
                <Route exact path="/" >
                <Home page="Home" />
                </Route>
                <Route exact path="/Portfolio" >
                <Portfolio page="Typology" title="Portfolio"/>
                </Route>

                <Route exact path="/Selection" >
                <Home  page="Selection" title="THEME"/>
                </Route>
                <Route exact path="/Biographie" >
                <Home  page="Biographie" title="Biographie"/>
                </Route>
                <Route exact path="/Portrait-Fiction" >
                <Home  page="Portrait-Fiction" title="Portrait Fiction"/>
                </Route>
                <Route exact path="/Agenda" >
                <Home page="Agenda" title="Agenda"/>
                </Route>
                <Route exact path="/Contact" >
                <Contact  page="Contact" title="Contact"/>
                </Route>
              </Switch>
    
        <Footer/>
        </Router>

      }


    </div>
  );
};

export default App;
